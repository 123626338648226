html
  scroll-behavior: smooth
  overflow-x: hidden

body
  margin: 0
  padding: 0
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased
  &.modal-open
    overflow: hidden
    height: 100vh
    max-height: 100vh
    & > div
      overflow: hidden
  &.mobile-filters-open
    @media (max-width: 991px)
      overflow: hidden
      height: 100vh
      max-height: 100vh
      & > div
        overflow: hidden

p
  margin: 0

*
  box-sizing: border-box

input
  outline: none

/* Remove arrows in number input Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.mobile-hidden
  @media (max-width: 991px)
    display: none

.invisible
  width: 0
  height: 0
  opacity: 0
  margin: 0
  padding: 0
  position: absolute

input, textarea
  &:focus, &:focus-visible
    outline: none


.info-tooltip-wrapper
  display: flex
  align-items: center
  justify-content: center

.info-tooltip
  max-width: 300px

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '')
  $index: str-index($string, $search)

  @if $index
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace)


  @return $string


// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg)
  $src: null

  $extmods: (eot: '?', svg: '#' + str-replace($name, ' ', '_'))

  $formats: (otf: 'opentype', ttf: 'truetype')

  @each $ext in $exts
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext)
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext)
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma)

  @font-face
    font-display: swap
    font-family: quote($name)
    font-style: $style
    font-weight: $weight
    src: $src


@include font-face(Poppins700, '../fonts/Poppins-Bold', 700, normal, ttf)
@include font-face(Poppins600, '../fonts/Poppins-SemiBold', 600, normal, ttf)
@include font-face(Poppins500, '../fonts/Poppins-Medium', 500, normal, ttf)
@include font-face(Poppins400, '../fonts/Poppins-Regular', 400, normal, ttf)
@include font-face(Poppins300, '../fonts/Poppins-Light', 300, normal, ttf)
@include font-face(Poppins200, '../fonts/Poppins-ExtraLight', 200, normal, ttf)


// SVG SPRITES =============================================================>

#common-icons-contacts_page,
#common-icons-contracts_page,
#common-icons-detail_view,
#common-icons-forgot_password,
#common-icons-sign_in_page,
#common-icons-signup_page,
#common-icons-listview_page,
#common-icons-entry_page,
#common-icons-error_page,
#common-icons-guides,
#common-icons-listview_page,
#common-icons-signup_page,
#common-icons-unsubscribe-page
  display: block !important
  height: 0
  width: 0
  overflow: hidden
