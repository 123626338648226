.search-section
  background: #FFFCF3
  @media (min-width: 992px)
    background: none

  .container
    min-height: calc(100vh - 82px)
    padding-top: 88px
    padding-bottom: 150px
    @media (min-width: 992px)
      padding-top: 168px

  h1
    font-family: Poppins600, sans-serif
    margin-bottom: 40px
    margin-top: 0
    color: #444
    text-align: center
    font-size: 36px
    line-height: 46px
    position: relative
    z-index: 1
    text-transform: capitalize
    @media (min-width: 992px)
      max-width: 350px
      margin-bottom: 24px
      font-size: 40px
      text-align: left
      color: white
      line-height: 51px

  &__gallery-place-info
    position: relative
    display: inline-flex
    padding: 8px 16px
    background: rgba(255,252,243, 0.7)
    backdrop-filter: saturate(180%) blur(10px)
    border-radius: 10px
    flex-direction: column
    align-items: flex-end
    text-decoration: none
    color: black
    p
      line-height: 24px
      &:first-child
        font-family: Poppins500, sans-serif
        font-size: 16px
      &:last-child
        font-family: Poppins400, sans-serif
        font-size: 14px
        display: flex
        align-items: center
        svg
          fill: #D15E26
          margin-right: 8px
    &:hover p:first-child
      text-decoration: underline

  .image-gallery
    width: 100vw
    height: 100vh
    position: absolute
    top: 0
    left: 0
    @media (max-width: 991px)
      display: none

  .image-gallery-content .image-gallery-slide .image-gallery-image
    max-height: unset !important

  .image-gallery-slide .image-gallery-image
    width: 100%
    height: 100%
    object-fit: cover !important

  .image-gallery-image-wrapper
    position: relative
    .container
      padding-top: 0 !important
      height: auto !important
      position: relative
      bottom: 100px
      text-align: right

  .image-gallery-custom-right-nav,
  .image-gallery-custom-left-nav
    position: absolute
    background: rgba(255,252,243, 0.7)
    backdrop-filter: saturate(180%) blur(10px)
    padding: 12px
    border-radius: 50%
    bottom: 124px
    z-index: 1
    cursor: pointer
    svg
      fill: black

  .image-gallery-custom-right-nav
    right: 16px
    @media (min-width: 576px)
      right: calc((100vw - 540px) / 2)
    @media (min-width: 768px)
      right: calc((100vw - 720px) / 2)
    @media (min-width: 992px)
      right: calc((100vw - 960px) / 2)
    @media (min-width: 1200px)
      right: calc((100vw - 1184px) / 2)
    @media (min-width: 1326px)
      right: calc((100vw - 1294px) / 2)

  .image-gallery-custom-left-nav
    right: 16px
    @media (min-width: 576px)
      right: calc(((100vw - 540px) / 2) + 70px)
    @media (min-width: 768px)
      right: calc(((100vw - 720px) / 2) + 70px)
    @media (min-width: 992px)
      right: calc(((100vw - 960px) / 2) + 70px)
    @media (min-width: 1200px)
      right: calc(((100vw - 1184px) / 2) + 70px)
    @media (min-width: 1326px)
      right: calc(((100vw - 1294px) / 2) + 70px)

  .image-gallery-content,
  .image-gallery-slide-wrapper,
  .image-gallery-swipe,
  .image-gallery-slides,
  .image-gallery-slide,
  .image-gallery-image-wrapper
    height: 100%

  .image-gallery-image-filter
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    opacity: 0.5
    background: #333

  .no-commission-container
    z-index: 1
    position: relative
    padding-top: 0
    min-height: 0
    height: 0
    padding-bottom: 0
    @media (max-width: 991px)
      text-align: center

  .no-commission
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    position: relative
    display: inline-flex
    bottom: 103px
    text-align: start
    @media (max-width: 991px)
      box-shadow: none
      padding: 12px 0
    @media (min-width: 992px)
      width: 383px

