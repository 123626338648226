.faq
  padding-top: 40px
  @media (min-width: 992px)
    padding-bottom: 44px

  &__headline
    font-family: Poppins600, sans-serif
    color: #444
    font-size: 20px
    line-height: 16px
    margin-bottom: 16px
    @media (min-width: 992px)
      font-size: 24px

  &__list-item
    cursor: pointer
    overflow: hidden
    border: 1px solid #9C9C9C
    margin-bottom: 16px
    padding: 16px 16px 0
    &:hover
      border-color: #E8753D
    @media (min-width: 992px)
      padding: 24px 24px 0
      margin-bottom: 24px

    h3
      font-family: Poppins400, sans-serif
      color: #000
      font-size: 16px
      line-height: 16px
      padding: 0 16px 16px 0
      position: relative
      user-select: none
      margin: 0
      letter-spacing: 0.5px
      svg
        position: absolute
        right: -8px
        top: 0
        fill: #6C7072
      @media (min-width: 992px)
        padding: 0 24px 24px 0

    &.close
      .faq__list-item-body
        min-height: 0
        height: 0
        padding: 0 0 0
        visibility: hidden
        animation: .25s ease 0s close
      svg
        transform: rotate(0deg)
        transition: transform ease 0.3s

    &.open
      svg
        transform: rotate(-180deg)
        transition: transform ease 0.3s
      .faq__list-item-body
        height: auto
        min-height: 24px
        padding: 0 0 16px
        visibility: visible
        animation: .25s ease 0s open

  &__list-item-body
    font-family: Poppins200, sans-serif
    color: #9C9C9C
    font-size: 16px
    line-height: 16px
    min-height: 0
    height: 0
    padding: 0 0 0
    visibility: hidden
    margin-bottom: 0
    a
      display: inline-block
      margin-top: 0
      line-height: 24px
      margin-left: 4px
    //@media (min-width: 992px)
    //  font-size: 24px

@keyframes open
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
  to
    height: auto
    min-height: 24px
    visibility: visible
    padding: 0 0 16px

@keyframes close
  from
    height: auto
    min-height: 24px
    visibility: visible
    padding: 0 0 16px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
