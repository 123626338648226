.footer
  padding: 16px 0
  @media (min-width: 992px)
    padding: 32px 0

  &__logo
    width: 100%
    height: auto
    margin-bottom: 16px
    @media (min-width: 992px)
      margin-bottom: 32px

  &__info
    font-family: Poppins500, sans-serif
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    line-height: 16px
    margin-bottom: 8px
    color: #444
    @media (min-width: 992px)
      font-size: 16px

  &__phone-link, &__email-link, &__cookie-link
    color: #444
    &:hover
      color: #E8753D

  &__phone-link
    text-decoration: none

  &__social-icons
    display: flex
    align-items: center
    justify-content: center
    a
      margin: 8px 8px 0 8px
