.no-commission
  padding: 12px 16px
  border-radius: 10px
  background: rgba(255, 252, 243, 0.90)
  display: flex
  align-items: center

  svg
    fill: #E8753D
    margin-right: 19px

  &__text-wrapper
    font-size: 14px
    letter-spacing: 0.08px
    div
      font-family: Poppins600, sans-serif
      max-width: 266px
