.error-page

  &__content-wrapper
    min-height: calc(100vh - 270px)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    text-align: center
    @media (min-width: 992px)
      min-height: calc(100vh - 365px)
    h1
      font-family: Poppins600, sans-serif
      font-size: 24px
      margin-bottom: 40px
      line-height: 30px
      @media (min-width: 768px)
        font-size: 32px
        line-height: 40px
      @media (min-width: 992px)
        font-size: 40px
        line-height: 51px

  &__error-description
    font-family: Poppins400, sans-serif
    font-size: 18px
    margin-bottom: 24px
    line-height: 23px
    a
      text-decoration: none
      color: #E8753D
      &:hover
        color: #D15E26
        text-decoration: underline
    @media (min-width: 992px)
      font-size: 20px
      line-height: 25px
