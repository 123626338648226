
.entry-main
  display: flex
  flex-direction: column
  // padding: 8rem 2rem
  background: #f5f4c8
  //background-image: url("../../../src/images/entrypage/header-background.png")
  background-repeat: no-repeat
  background-position: center
  &__wrap
    padding: 8rem 2rem
    background: #f5f4c8
    //background-image: url("../../../src/images/entrypage/header-background.png")
    background-repeat: no-repeat
    background-position: center

  &__main-title
    font-size: 2rem
    text-align: center
    margin: 0


.panel
  width: 100%

.flicker-section
  padding: 2rem
  padding-top: 200px

.slider-container
  width: 100%
  overflow-x: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.slider-wrapper
  display: flex
  align-items: center
  width: fit-content
  &.container
    @media (max-width: 991px)
      padding: 0 !important
      width: fit-content !important
      max-width: fit-content !important
      .row
        margin: 0
        display: block
      .col-lg-6, .col-lg-3, col-lg-4, col-xl-3
        padding: 0
    @media (min-width: 992px)
      display: block




