
.why-us
  background: #FFFCF3

  &__wrapper
    padding: 24px 0 48px
    display: flex
    justify-content: space-between
    @media (min-width: 992px)
      padding: 114px 0
      align-items: center
      display: flex

  &__header
    font-family: Poppins700, sans-serif
    color: #444
    font-size: 20px
    line-height: 16px
    margin-bottom: 16px
    span
      color: #E8753D
    @media (min-width: 992px)
      font-size: 40px
      margin-bottom: 72px
      line-height: 51px

  &__list-item
    font-family: Poppins700, sans-serif
    display: flex
    align-items: center
    color: #000
    font-size: 16px
    line-height: 16px
    margin-bottom: 24px
    svg
      color: #E8753D
      margin-right: 16px
    @media (min-width: 992px)
      margin-bottom: 32px

  &__search-button
    font-family: Poppins600, sans-serif
    border: 1px solid #E8753D
    width: 100%
    border-radius: 16px
    background: white
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    color: #E8753D
    font-size: 16px
    line-height: 16px
    margin-top: 8px
    cursor: pointer
    svg
      margin-right: 8px
      fill: #E8753D
    &:hover
      background: #E8753D
      color: #FFF
      svg
        fill: #FFF
    @media (min-width: 992px)
      width: 260px
      margin-top: 40px

  &__jakob
    display: none
    @media (min-width: 992px)
      display: block
      width: 100%
      height: auto
      border-radius: 16px
