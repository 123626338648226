.guides
  padding: 48px 0
  @media (min-width: 992px)
    padding: 40px 0

  &__top
    padding-bottom: 16px
    display: flex
    align-items: center
    justify-content: space-between
    div, a
      font-family: Poppins600, sans-serif
      font-size: 20px
      font-weight: 800
      color: #444
      text-decoration: none
      line-height: 20px
      @media (min-width: 992px)
        font-size: 24px
        line-height: 30px
    a:hover
      color: #E8753D

  &__list
    columns: 3
    column-gap: 0
    padding-left: 16px
    @media (min-width: 992px)
      columns: unset
      padding-left: 0

  &__guide
    @media (min-width: 992px)
      &:last-child
        display: none

  &__guide-link
    font-family: Poppins600, sans-serif
    display: flex
    align-items: center
    margin: 0 16px 8px 0
    height: 100px
    color: #FFF
    font-size: 16px
    line-height: 16px
    text-decoration: none
    border-radius: 16px
    position: relative
    overflow: hidden
    padding: 16px
    &:hover
      text-decoration: none
      color: #FFF
      filter: drop-shadow(rgba(0, 0, 0, 25%) 0 4px 4px)
    span
      max-width: 252px
      z-index: 1
    @media (max-width: 991px)
      width: 330px
    @media (min-width: 992px)
      margin-right: 0
      width: 100%

  &__guide-image
    height: 100%
    width: 100%
    object-fit: cover
    object-position: center
    position: absolute
    right: 0
    &.default
      object-position: bottom

  &__guide-background
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    opacity: 0.5
    background: #333
