
.popular-places
  background: #FFFCF3
  padding: 56px 0 44px

  .slider-wrapper
    padding-left: 16px
    @media (min-width: 992px)
      display: flex !important

  &__top
    padding-bottom: 16px
    display: flex
    align-items: center
    justify-content: space-between
    div, a
      font-family: Poppins600, sans-serif
      font-size: 16px
      color: #444
      text-decoration: none
      line-height: 20px
      @media (min-width: 992px)
        font-size: 24px
        line-height: 30px
    a:hover
      color: #E8753D

  &__list-wrapper
    display: flex
    padding: 0 16px
    @media (min-width: 992px)
      display: grid
      padding: 0
      gap: 16px
    @media (min-width: 992px) and (max-width: 1199px)
      grid-template-columns: repeat(3, minmax(0, 1fr))
    @media (min-width: 1200px)
      grid-template-columns: repeat(4, minmax(0, 1fr))

  &__place-wrapper
    border-radius: 16px
    width: 337px
    margin-right: 16px
    &:last-child
      margin-right: 0
    @media (min-width: 992px)
      margin-right: 0
      width: 100%
    @media (min-width: 992px) and (max-width: 1199px)
      &:last-child
        display: none

  .place__img, .place__img-placeholder, .place__img-placeholder-cover, .place__img-wrapper
    height: 230px !important

  .place__img-placeholder-label
    top: 95px !important
