.benefits
  padding: 40px 0 48px

  .slider-wrapper
    @media (min-width: 992px)
      margin: auto

  &__item-wrapper
    padding-right: 32px
    &:first-child
      padding-left: 32px
    &:last-child
      padding-right: 32px

  &__item
    display: flex
    align-items: center
    flex-direction: column
    min-width: 150px
    max-width: 150px
    p
      font-family: Poppins500, sans-serif
      margin-top: 8px
      text-align: center
      color: #5A6369
      font-size: 16px
      line-height: 21px
    svg
      fill: #E8753D
